<template>
  <div>
    <el-row>
      <el-col style="min-height: 100vh; position: relative" :span="15" class="bg">
        <h1 class="title">
          <!-- <img
            src="../../assets/portal/logo.png"
            alt=""
          /> -->
          榆林市榆阳区人力资源报名服务系统
        </h1>
        <div class="bgimg"></div>
        <div style="
            position: absolute;
            text-align: center;
            bottom: 0;
            width: 100%;
            margin-bottom: 10px;
          ">
          <a href="https://beian.miit.gov.cn/
  " style="color: #fff; text-decoration: none;font-size: 14px;"
            target="_blank">版权所有：榆林市榆阳区人力资源服务有限公司报名系统  陕ICP备2021012310号-1</a>
        </div>
      </el-col>

      <el-col :span="9" v-show="!state.resetPwdStatus">
        <h1 class="secondTitle">榆阳人力资源网</h1>
        <div v-show="flag" class="login">
          <el-form ref="formRef" :model="oginInput" :rules="rules">
            <el-row>
              <el-col>
                <p class="pTitle">账号登陆</p>
              </el-col>
              <el-col :span="13" :push="6">
                <el-form-item prop="userId">
                  <el-input v-model="oginInput.userId" class="w-50" size="large" placeholder="请输入账号"
                    :prefix-icon="User" />
                </el-form-item>
              </el-col>
              <el-col :span="13" :push="6">
                <el-form-item prop="userPwd">
                  <el-input v-model="oginInput.userPwd" class="w-50" size="large" type="password" placeholder="请输入密码"
                    :prefix-icon="Unlock" />
                </el-form-item>
              </el-col>
              <el-col style="margin-top: 5%">
                <el-row>
                  <el-col :span="7" :push="6">
                    <el-form-item prop="verCode">
                      <el-input v-model="oginInput.verCode" size="large" placeholder="请输入验证码" :prefix-icon="Picture" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="5" :push="6">
                    <img style="height: 0.2083rem; width: 0.6771rem; margin-left: 10px" @click="getVer()"
                      :src="'data:image/jpg;base64,' + verData.img" />
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="13" style="margin-left: 25%">
                <el-button class="w-50" type="primary" @click="login">登录</el-button>
                <p style="margin-top: 10px">
                  <span>没有账号? <a class="resg" @click="reg">去注册</a></span>
                  <span style="float: right; margin-right: 0.2083rem">
                    <a class="pwd" @click="toresetPwd">忘记密码?</a>
                  </span>
                </p>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <Register v-show="!flag" :flag="flag" @callback="back" />
      </el-col>
      <el-col :span="9" v-show="state.resetPwdStatus">
        <h1 class="secondTitle">榆阳人力资源网</h1>
        <el-form ref="formResetPwdRef" :model="resetData" :rules="rulesResetPwd">
          <el-row>
            <el-col>
              <p class="pTitle">密码重置</p>
            </el-col>
            <el-col :span="13" :push="6">
              <el-form-item prop="userId">
                <el-input v-model="resetData.userId" class="w-50" size="large" placeholder="请输入账号" :prefix-icon="User" />
              </el-form-item>
            </el-col>
            <el-col style="margin-top: 15px">
              <el-row>
                <el-col :span="7" :push="6">
                  <el-form-item prop="imgVerCode">
                    <el-input v-model="resetData.imgVerCode" size="large" placeholder="请输入图形验证码" :prefix-icon="Picture" />
                  </el-form-item>
                </el-col>
                <el-col :span="5" :push="6">
                  <img style="height: 0.2083rem; width: 0.6771rem; margin-left: 10px" @click="getVer()"
                    :src="'data:image/jpg;base64,' + verData.img" />
                </el-col>
              </el-row>
            </el-col>
            <el-col style="margin-top: 15px">
              <el-row>
                <el-col :span="7" :push="6">
                  <el-form-item prop="verCode">
                    <el-input v-model="resetData.verCode" size="large" placeholder="请输入短信验证码" :prefix-icon="Picture" />
                  </el-form-item>
                </el-col>

                <el-col :span="5" :push="6">
                  <!-- <img
                    style="height: 40px; width: 0.6771rem; margin-left: 10px"
                    @click="getVer()"
                    :src="'data:image/jpg;base64,' + verData.img"
                  /> -->
                  <el-button style="height: 0.2083rem; width: 0.6771rem; margin-left: 10px" type="primary"
                    @click="getVerCode" :disabled="state.getVerCodeStatus">{{
                      state.getVerCodeStatus
                      ? state.getVerCodeNum + "秒后可重新获取"
                      : "获取手机验证码"
                    }}</el-button>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="13" :push="6">
              <el-form-item prop="userPwd">
                <el-input v-model="resetData.userPwd" class="w-50" size="large" type="password" placeholder="请输入新密码"
                  :prefix-icon="Unlock" />
              </el-form-item>
            </el-col>

            <el-col :span="13" style="margin-left: 25%">
              <el-button class="w-50" type="primary" @click="resetPwd">重置密码</el-button>
              <p style="margin-top: 10px">
                <span> <a class="resg" @click="toLogin">返回登录</a></span>
              </p>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { post, get } from "@/api/api";
import {
  onMounted,
  reactive,
  ref,
  watch,
  onUnmounted,
} from "@vue/runtime-core";
import { User } from "@element-plus/icons-vue";
import { Unlock } from "@element-plus/icons-vue";
import { Picture } from "@element-plus/icons-vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Register from "../register/inde.vue";
import { getCode, decrypt } from "@/utils/encrypt.js";
import { ElMessage } from "element-plus";

let router = useRouter();
let store = useStore();

onMounted(async () => {
  getVer();
  // 绑定监听事件
  window.addEventListener("keydown", keyDown);
});
onUnmounted(() => {
  // 销毁事件
  window.removeEventListener("keydown", keyDown, false);
});

let verData = reactive({});
const getVer = async () => {
  let res = await get("/verCode/get");
  Object.assign(verData, res.result);
};

let oginInput = reactive({ userId: "", userPwd: "" });
const login = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      let userPwd = getCode(oginInput.userPwd);
      store.commit("user/login", {
        oginInput,
        userPwd,
        verCodeId: verData.imgUUID,
        router,
      });
      getVer();
    }
  });
};
let flag = ref(true);
const reg = async () => {
  flag.value = false;
};
const back = (name, pwd) => {
  if (name) {
    oginInput.userId = name;
    oginInput.userPwd = pwd;
  }
  flag.value = true;
};
watch(flag.value, (val) => {
  if (val) {
    getVer();
  }
});
const rules = reactive({
  userId: [{ required: true, message: "请输入账号", trigger: "change" }],
  userPwd: [
    { required: true, message: "请输入密码", trigger: "change" },
    { min: 6, max: 18, message: "密码长度为 6 到 18", trigger: "blur" },
  ],
  imgVerCode: [{ required: true, message: "请输入图形验证码", trigger: "blur" }],
  verCode: [{ required: true, message: "请输入短信验证码", trigger: "blur" }],
});
const rulesResetPwd = reactive({
  userId: [{ required: true, message: "请输入账号", trigger: "change" }],
  userPwd: [
    { required: true, message: "请输入新密码", trigger: "change" },
    { min: 6, max: 18, message: "密码长度为 6 到 18", trigger: "blur" },
  ],
  imgVerCode: [{ required: true, message: "请输入图形验证码", trigger: "blur" }],
  verCode: [{ required: true, message: "请输入短信验证码", trigger: "blur" }],
});
const formRef = ref();
const formResetPwdRef = ref();

const toresetPwd = () => {
  state.resetPwdStatus = true;
};
const toLogin = () => {
  state.resetPwdStatus = false;
};
// 重置密码
const resetPwd = async () => {
  formResetPwdRef.value.validate(async (valid) => {
    const res = await post("/examApply/user/forgetPwd", {
      newPwd: getCode(resetData.userPwd),
      verCode: resetData.verCode,
      safeNum: resetData.userId,
      userId: resetData.userId,
    });
    if (res.code === 200) {
      ElMessage.success("密码重置成功，请重新登陆");
      toLogin();
    } else {
      ElMessage.error(res.message || "返回数据错误");
    }
  });
};
let state = reactive({
  resetPwdStatus: false,
  getVerCodeStatus: false,
  getVerCodeNum: 60,
  mysetInterval: null,
});
let resetData = reactive({ newPwd: "", verCode: "", userId: "", imgVerCode: "", verCodeId: "" });
// 重置密码获取手机验证码
const getVerCode = async () => {
  console.log();
  if (!resetData.userId) {
    ElMessage.error("请输入账号");
    return;
  }
  const res = await get("/examApply/user/sendPhoneCode", {
    phone: resetData.userId,
    verCodeId: verData.imgUUID,
    imgVerCode: resetData.imgVerCode,
  });
  if (res.code === 200) {
    console.log(res);
    ElMessage.success(res.message);
    state.getVerCodeStatus = true;
    state.mysetInterval = setInterval(() => {
      state.getVerCodeNum--;
      if (state.getVerCodeNum <= 0) {
        state.getVerCodeStatus = false;
        state.getVerCodeNum = 60;
        clearInterval(state.mysetInterval);
      }
    }, 1000);
  } else {
    ElMessage.error(res.message || "返回数据错误");
  }

};
const keyDown = (e) => {
  // 回车则执行登录方法 enter键的ASCII是13
  if (e.key == "Enter") {
    login(); // 定义的登录方法
    e.preventDefault(); // 去掉默认的换行
  }
};
</script>

<style lang="scss" scoped>
@import "../../utils/btn.css";

.login {
  width: 100%;
  height: 500px;
}

.w-50 {
  width: 1.9271rem;
  margin-top: 6%;
}

.w-25 {
  width: 25%;
  margin-top: 5%;
}

.change {
  display: inline-block;
  color: blue;
  cursor: pointer;
  margin-top: -20px;
}

.resg {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.pwd:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}

.bg {
  background-image: url(../../assets/bg.jpg);
  background-size: 100% 100%;
}

.bgimg {
  height: 2.0833rem;
  width: 2.3438rem;
  background-image: url(../../assets/bgi.png);
  background-size: 100% 100%;
  margin-top: 10%;
  margin-left: 1.9792rem;
}

.title {
  margin-top: 20%;
  text-align: center;
  font-size: 0.2083rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;

  img {
    width: 0.2083rem;
    vertical-align: middle;
    margin-top: -0.0208rem;
    margin-right: 0.0521rem;
  }
}

.secondTitle {
  margin-top: 35%;
  text-align: center;
  font-size: 0.1563rem;
  font-family: PingFang SC;
  font-weight: 800;
  color: #333333;
}

.pTitle {
  margin-top: 0.2969rem;
  text-align: center;
  font-size: 0.0833rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

.el-form-item {
  margin-bottom: 0px;
}
</style>
